import {Box} from "@mui/material";
import {FC, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {QuizResultBreakdown} from "./QuizResultBreakdown";
import {QuizResultSummary} from "./QuizResultSummary";
import {MarkedQuizResultDataProvider} from "@plumeuk/shapeshift-common/providers";
import {useParams} from "react-router-dom";
import {IQuiz} from "@plumeuk/shapeshift-types";

const useStyles = makeStyles()((theme) => ({
	quizResult: {
		textAlign: "left",
		[theme.breakpoints.down("sm")]: {
			marginTop: "15px"
		}
	},
	markedAnswer: {
		marginBottom: "60px"
	},
	label: {
		color: "#A8A8A8"
	},
	title: {
		margin: "60px 0 20px 0"
	},
	subTitle: {
		marginBottom: "40px",
		color: "#A8A8A8"
	}
}));

interface IProps {
	quiz?: IQuiz
}

export const QuizResult: FC<IProps> = ({quiz}) => {
	const {classes} = useStyles();
	const [checkAnswers, setCheckAnswers] = useState(false);
	const {courseSlug, resultId, moduleSlug: quizSlug} = useParams();

	useEffect(() => {
		setCheckAnswers(false);
	}, [quiz]);

	return (
		<MarkedQuizResultDataProvider courseSlug={courseSlug} quizSlug={quizSlug} resultId={resultId}>
			{({markedQuizResult}) =>
				<Box className={classes.quizResult}>
					{markedQuizResult && (checkAnswers || !markedQuizResult.pass
						? <QuizResultBreakdown quiz={quiz} markedAnswers={markedQuizResult} />
						: <QuizResultSummary quiz={quiz} markedAnswers={markedQuizResult} onCheckAnswersAction={() => setCheckAnswers(true)}/>
					)}
				</Box>
			}
		</MarkedQuizResultDataProvider>
	);
}